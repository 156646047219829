/**
 * --------------------------------------------------------------------------
 * Bootstrap index.umd.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import Button from './src/button.js'
import Collapse from './src/collapse.js'
import Dropdown from './src/dropdown.js'

export default {
  Button,
  Collapse,
  Dropdown,
}
